<script setup>
import { userAuthState } from "@/@core/auth"

import IncidentTable from "@/views/incident/incident-panel/IncidentTable.vue"
import IncidentDrawer from "@/views/incident/incident-panel/IncidentDrawer.vue"
import FilterComponent from "@/@core/components/custom/FilterComponent.vue"

definePage({
  meta: {
    name: "incident-incident",
    subject: "incident.incident",
    action: "read",
  },
})

const auth = userAuthState()

const filters = ref({})
const { data: filterOptions } = await useApi(createUrl('/incidents/filters'))


const isViewDrawerVisible = ref(false)


const refTable = ref()


const showIncident = data => {
  // viewData.value = data;
  isViewDrawerVisible.value = true
}

// const tab = ref()
</script>

<template>
  <section>
    <VRow class="match-height mb-6">
      <VCol
        cols="12"
        md="6"
        lg="6"
      >
        <FilterComponent
          v-model:filters="filters"
          :filters-options="filterOptions"
        />
      </VCol>
      <VCol
        cols="12"
        md="6"
        lg="6"
      >
        <VCard>
          <VCardItem>
            Coming Soon
          </VCardItem>
        </VCard>
      </VCol>
    </VRow>

    <IncidentTable
      ref="refTable"
      :filters="filters"
      @show="showIncident"
    /> 

    <!-- <VTabs v-model="tab">
      <VTab value="list">
        List
      </VTab>
      <VTab value="archived">
        Archived
      </VTab>
    </VTabs>

    <VWindow v-model="tab">
      <VWindowItem value="list">
        <IncidentTable
          ref="refTable"
          :filters="filters"
          :archived="false"
          @show="showIncident"
        /> 
      </VWindowItem>
      <VWindowItem value="archived">
        <IncidentTable
          ref="refTable"
          :filters="filters"
          :archived="true"
          @show="showIncident"
        /> 
      </VWindowItem>
    </VWindow> -->
    
    <!-- <IncidentTable
      ref="refTable"
      :filters="filters"
      @show="showIncident"
    />  -->

    <!--
      <IncidentDrawer
      v-model:isDrawerOpen="isViewDrawerVisible"
      :data="viewData"
      /> 
    -->
  </section>
</template>
